@import "../../node_modules/antd/dist/antd.min.css";
@import "./fonts/Montserrat/stylesheet.css";
@import "./fonts/Inter/stylesheet.css";
@import "../../node_modules/cropperjs/dist/cropper.min.css";

#root {
    display: flex;
    height: 100vh;
}

html, body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: Montserrat, Arial, sans-serif;
}